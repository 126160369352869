.common-navbar {
  background-color: $bp-blue;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;

  .navbar {
    font-family: $sans-serif;
    padding-left: 0;
    padding-right: 0;
    position: static;
  }

  .navbar-brand {
    font-family: $poppins;
    font-weight: 600;
    text-shadow: none;

    img {
      margin-right: 0.5rem;
    }
  }

  .navbar-nav {
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-link {
      font-family: $syne;
      font-size: 0.9rem;
      font-weight: bold;
      color: $white;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .contact-btn {
      font-family: $syne;
      font-size: 0.9rem;
      font-weight: bold;
      border-radius: 0.125rem;
      margin-left: 1rem;
      margin-right: 1rem;

      @media screen and (max-width: 768px) {
        color: $white;
      }
    }

    .contact-btn--outline {
      background-color: $white;
      color: $dark-gray;
      padding: 0.5rem 1.25rem;
      border: 0.063rem solid;
      transition: all 0.3s ease-out;

      @media screen and (max-width: 768px) {
        background-color: transparent;
        color: $white;
        padding: 0rem 0rem;
        border: 0rem solid;
        transition: none;
      }
    }

    .contact-btn:hover {
      background: transparent;
      color: $white;
      border: 0.063rem solid $white;
      transition: all 0.3s ease-out;
      text-decoration: none;

      @media screen and (max-width: 768px) {
        background: transparent;
        color: transparent;
        border: 0rem solid;
        transition: none;
      }
    }
  }

  .navbar-toggler {
    border-color: transparent;
  }

  &.transparent {
    background-color: transparent;
    transition: background 2.5s;

    &.expanded {
      background-color: $dark-blue;
      transition: background 0s;
      // background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba($bp-blue, 0) 56px, rgba($bp-blue, 1) 56px, rgba($bp-blue, 1) 100%);
    }
  }

  &.inverse {
    background-color: inherit;

    .navbar-brand {
      img {
        content: url('../../images/branding/logo.svg');
      }

      color: $bp-blue;
    }

    .navbar-nav {
      .nav-link {
        font-family: $syne;
        font-size: 0.9rem;
        font-weight: bold;
        margin-left: 1rem;
        margin-right: 1rem;
        color: $bp-blue;
      }
      .contact-btn {
        font-family: $syne;
        font-size: 0.9rem;
        font-weight: bold;
        border-radius: 0.125rem;
        margin-left: 1rem;
        margin-right: 1rem;

        @media screen and (max-width: 768px) {
          color: $bp-blue;
        }
      }

      .contact-btn--outline {
        background-color: $bp-blue;
        color: $white;
        padding: 0.5rem 1.25rem;
        border: 0.063rem solid;
        transition: all 0.3s ease-out;

        @media screen and (max-width: 768px) {
          background-color: transparent;
          color: $bp-blue;
          padding: 0rem 0rem;
          border: 0rem solid;
          transition: none;
        }
      }

      .contact-btn:hover {
        background: transparent;
        color: $bp-blue;
        border: 0.063rem solid $bp-blue;
        transition: all 0.3s ease-out;
        text-decoration: none;

        @media screen and (max-width: 768px) {
          background: transparent;
          color: $bp-blue;
          border: 0rem solid;
          transition: none;
        }
      }
    }

    &.transparent {
      &.expanded {
        background-color: $white;
      }
    }
  }
}
