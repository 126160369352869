.where-weve-been {
  text-align: center;
  font-family: $sans-serif;

  .title {
    height: 100px;
  }

  .company-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .company-link {
      flex: 1 0 21%;
      justify-content: center;

      @media screen and (max-width: 768px) {
        flex: 1 0 34%;
      }

      .company-img {
        object-fit: contain;
        max-width: 250px;
        width: 100%;
        max-height: 125px;
        padding: 28px;
        margin-bottom: 25px;
      }
    }
  }
}
