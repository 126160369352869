.member-profile {
  padding-top: 40px;
  text-align: center;

  .name {
    font-weight: 600;
    padding-top: 10px;
  }

  .position {
    font-size: 0.75rem;
  }
}
