.section-subtitle {
  font-family: $syne;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 30px 0 30px 0;
  display: flex;
  align-items: center;
}

.subtitle-blue {
  color: $bp-blue !important;
  font-size: 1.3rem;
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: $syne;
}
