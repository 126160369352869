.who-we-are {
    a {
        color: $pink;
    }

    &.container {
        padding: 0;
        display: flex;
        flex-direction: row;
    }

    &.body {
        flex: 1;
    }

    &.imgContainer {
        flex: 1;
        max-width: 100%;
    }

    &.img {
        width: 125%;
    }
}