.project-info {

    .title {
        color: $black;
        font-family: $syne;
        font-size: 3rem;
        font-weight: 500;
        margin-bottom: 1rem;
  
        @media (max-width: 39.99rem) {
          font-size: 10.3vw;
        }
  
        @media (min-width: 72rem) {
          font-size: 3.5rem;
        }
    }

    &.section-container {
        padding-top: 0; 
    }

    div {
        img {
            width: 95%;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    
    .btn {
        @extend .raised-button;
        margin-right: 20px;

        &.blue {
            margin-bottom: 20px;
        }

        &.white {
            margin-bottom: 20px;
        }
    }
    
    .flex-row {
        display: flex; 
        justify-content: space-around;
    }

    th, tr td strong {
        color: $black;
    }

    table {
        margin: 1.5rem 0; 
    }

    td {
        padding: 0.4rem;
    }

    hr {
        margin: 2.2rem 0;
    }

    #duration-table table {
        width: 100%; 
    }

    &.harmony-project {
        .hp-gif-demo p {
            display: flex;
            flex-direction: row;
            justify-content: center;
            
            img {
                width: 30%;
            }
        }

        #hp-expand-announcements {
            width: 50%
        }
    }
}

