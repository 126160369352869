.mailing-list-button {
  display: flex;
  align-items: center;

  .mailing-list-button-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0 !important;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .student-apply-heading {
      flex: 1 1 100%;
      margin-bottom: 3.75rem !important;
    }

    .mailing-list-button-text {
      font-family: $syne;
      font-weight: 500;
      font-size: 1.25rem;
      color: $black;
      text-align: left;
      max-width: 400px;

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }

    .mailing-list-button-form {
      display: flex;
      align-items: center;

      .mailing-list-button-input {
        font-family: $sans-serif;
        font-size: 1rem;
        border-radius: 3px;
        padding-left: 10px;
        margin-right: 20px;
        height: 46px;
        background-color: $white;
        border-width: 1px;
        border-style: solid;
        border-color: $gray;
        outline: none;

        @media screen and (max-width: 768px) {
          margin-right: 10px;
          width: 100%;
        }
      }
    }
  }
}
