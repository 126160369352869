@import 'partials/all';
@import 'pages/all';
@import 'components/all';

h1 {
  color: $black;
  font-family: $syne;
  font-weight: 700;

  .header {
    color: $white;
  }
}

h2, h3 {
  color: $black;
  font-family: $syne;
  font-weight: 700;
}

h4 {
  color: $gray;
  font-weight: 400;
}

.container {
  @media screen and (min-width: 992px) {
    max-width: 920px;
  }
}

.blue {
  color: $bp-blue;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.raised-button {
  &.btn {
    background-color: $white;
    background-image: none;
    border-radius: 3px;
    color: $black;
    font-family: $syne;
    font-weight: 600;
    padding: 10px 15px;
    text-decoration: none;
    text-shadow: none;
    user-select: none;
    -webkit-transition: 150ms;
    transition: 150ms;

    &.blue {
      background-color: $bp-blue;
      color: $white;

      &:hover {
        background-color: $bp-blue;
        color: $white;
      }

      &:focus {
        background-color: $bp-blue;
        color: $white;
      }

      &:active {
        background-color: $bp-blue !important;
        color: $white !important;
      }
    }

    &.white {
      border: 1px solid $bp-blue;
      background-color: $white;
      color: $bp-blue;

      &:hover {
        background-color: $white;
        color: $bp-blue;
      }

      &:focus {
        background-color: $white;
        color: $bp-blue;
      }

      &:active {
        background-color: $white !important;
        color: $bp-blue !important;
      }
    }

    &.outline-white {
      border: 1px solid $white;
      background-color: $transparent;
      color: $white;

      &:hover {
        background-color: $transparent;
        color: $white;
      }

      &:focus {
        background-color: $transparent;
        color: $white;
      }

      &:active {
        background-color: $transparent !important;
        color: $white !important;
      }
    }

    &.pink {
      border: 1px solid $pink;
      background-color: $pink;
      color: $white;

      &:hover {
        background-color: $pink;
        color: $white;
      }

      &:focus {
        background-color: $pink;
        color: $white;
      }

      &:active {
        background-color: $pink !important;
        color: $white !important;
      }
    }

    &.pink-outline {
      border: 1px solid $pink;
      background-color: $transparent;
      color: $pink;

      &:hover {
        background-color: $transparent;
        color: $pink;
      }

      &:focus {
        background-color: $transparent;
        color: $pink;
      }

      &:active {
        background-color: $transparent !important;
        color: $pink !important;
      }
    }

    &.outline-black {
      border: 1px solid $black;
      background-color: $transparent;
      color: $black;

      &:hover {
        background-color: $transparent;
        color: $black;
      }

      &:focus {
        background-color: $transparent;
        color: $black;
      }

      &:active {
        background-color: $transparent !important;
        color: $black !important;
      }
    }

    &:active {
      background-color: $white !important;
      color: $black !important;
    }

    &:focus {
      background-color: $white;
      color: $black;
    }

    &:hover {
      background-color: $white;
      color: $black;
      transform: translateY(-3px);
    }
  }
}
