.footer {
  background-color: $dark-gray;
  color: $white;
  font-family: $sans-serif;
  min-height: 35vh;

  a {
    text-decoration: none;

    &:hover {
      color: $white;
    }
  }

  .copyright {
    color: $white-40;
    font-size: 1rem;
  }

  .link {
    color: $white-60;
    font-family: $sans-serif;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }

  .logo-container {
    display: flex;

    .logo {
      font-family: $poppins;
      font-size: 1.25rem;
      font-weight: 600;
      text-shadow: none;
    }

    .logo-image {
      margin-right: 0.5rem;
    }
  }

  .social-icon {
    vertical-align: -2;
  }
}
