.contact-button {
  background-color: $white;
  color: $black;
  font-family: $sans-serif;
  min-height: 30vh;
  display: flex;
  flex-direction: row;

  .btn {
    display: inline-block;
    background-color: $bp-blue;
    color: $white;
    margin-right: 1rem;

    // &.facebook-button {
    //   background-color: $white;
    // }
  }

  .flex-container {
    display: flex;
    width: 100%;

    .subheader-container {
      flex: 1;
    }

    .buttons-container {
      align-self: flex-end;
      align-items: flex-end;

      @media screen and (max-width: 510px) {
        padding-left: 15px;
      }

      .raised-button {
        min-width: 10vw;
        @media screen and (max-width: 415px) {
          margin: 1vw;
        }
      }
    }
  }
}
