.project-page {
  padding-bottom: 200px;
  padding-top: 100px;

  .title {
    height: 100px;
  }

  .projects {
    display: flex;

    .card-deck {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .project-card {
        flex: 1 0 26%;
        max-width: 38%;

        @media screen and (max-width: 768px) {
          flex: 1;
          max-width: none;
          margin-top: 20px;
          margin-bottom: 20px;

          .card-text {
            min-height: 16vh;
          }
        }
      }
    }
  }
}
