.contact-form {
  font-family: $sans-serif;
  max-width: 600px;

  .btn {
    background-color: $bp-blue;
    color: $white;
  }

  .form-control {
    max-width: 300px;

    &.textarea {
      max-width: 100%;
    }
  }

  .form-label {
    font-weight: 600;
  }
}
