.our-values {
  display: flex;
  padding: 0;
  padding-bottom: 100px;
  padding-top: 100px;
  font-family: $sans-serif;

  .title {
    text-align: center;
  }

  .values-container {
    .values {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
