.project-card {
  margin-bottom: 40px;
  font-family: $syne;

  &:hover {
    text-decoration: none;
  }

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &.card {
    background: $white;
    border: none;
    color: $black;
    cursor: pointer;
    height: 100%;
    padding: 20px 20px;

    .card-title {
      font-weight: 700;
      min-height: 50px;
    }

    .card-text {
      color: #505050;
    }

    &:hover {
      box-shadow: 2px 2px 10px rgba(44, 62, 80, 0.1);
      transform: translateY(-2px);
      transition: 0.3s ease;
    }

    .card-img-top {
      width: 100%;
      height: 30vh;
      object-fit: contain;
    }

    .blue {
      padding-left: 1.25rem;
    }
  }
}
