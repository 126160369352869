.section-container {
  background-color: $white;
  color: $gray;
  min-height: 50vh;
  padding: 100px 15px;

  &.off-white {
    background-color: $off-white;
  }

  .last {
    margin-bottom: 50px;
  }

  .link {
    &:hover {
      text-decoration: none;
    }
  }
}
