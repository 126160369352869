/* work-sans-300 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/WorkSans/work-sans-v11-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/WorkSans/work-sans-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/WorkSans/work-sans-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/WorkSans/work-sans-v11-latin-300.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/WorkSans/work-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/WorkSans/work-sans-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/WorkSans/work-sans-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/WorkSans/work-sans-v11-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/WorkSans/work-sans-v11-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/WorkSans/work-sans-v11-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/WorkSans/work-sans-v11-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/WorkSans/work-sans-v11-latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/WorkSans/work-sans-v11-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/WorkSans/work-sans-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/WorkSans/work-sans-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/WorkSans/work-sans-v11-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/WorkSans/work-sans-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/WorkSans/work-sans-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/WorkSans/work-sans-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/WorkSans/work-sans-v11-latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/WorkSans/work-sans-v11-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/WorkSans/work-sans-v11-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/WorkSans/work-sans-v11-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/WorkSans/work-sans-v11-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/WorkSans/work-sans-v11-latin-italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* syne-regular - latin */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Syne/syne-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/Syne/syne-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Syne/syne-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Syne/syne-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Syne/syne-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/Syne/syne-v3-latin-regular.svg#Syne') format('svg'); /* Legacy iOS */
}
/* syne-500 - latin */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/Syne/syne-v3-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/Syne/syne-v3-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Syne/syne-v3-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Syne/syne-v3-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Syne/syne-v3-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/Syne/syne-v3-latin-500.svg#Syne') format('svg'); /* Legacy iOS */
}
/* syne-600 - latin */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/Syne/syne-v3-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/Syne/syne-v3-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Syne/syne-v3-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Syne/syne-v3-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Syne/syne-v3-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/Syne/syne-v3-latin-600.svg#Syne') format('svg'); /* Legacy iOS */
}
/* syne-700 - latin */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/Syne/syne-v3-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/Syne/syne-v3-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Syne/syne-v3-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Syne/syne-v3-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Syne/syne-v3-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/Syne/syne-v3-latin-700.svg#Syne') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  src: url('../../fonts/Poppins/Poppins-italic.eot'); /* IE9 Compat Modes */
  src:
    url('../../fonts/Poppins/Poppins-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/Poppins/Poppins-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/Poppins/Poppins-italic.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/Poppins/Poppins-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/Poppins/Poppins-italic.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('../../fonts/Poppins/Poppins-400.eot'); /* IE9 Compat Modes */
  src:
    url('../../fonts/Poppins/Poppins-400.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/Poppins/Poppins-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/Poppins/Poppins-400.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/Poppins/Poppins-400.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/Poppins/Poppins-400.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url('../../fonts/Poppins/Poppins-500.eot'); /* IE9 Compat Modes */
  src:
    url('../../fonts/Poppins/Poppins-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/Poppins/Poppins-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/Poppins/Poppins-500.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/Poppins/Poppins-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/Poppins/Poppins-500.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url('../../fonts/Poppins/Poppins-600.eot'); /* IE9 Compat Modes */
  src:
    url('../../fonts/Poppins/Poppins-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/Poppins/Poppins-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/Poppins/Poppins-600.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/Poppins/Poppins-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/Poppins/Poppins-600.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url('../../fonts/Poppins/Poppins-700.eot'); /* IE9 Compat Modes */
  src:
    url('../../fonts/Poppins/Poppins-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/Poppins/Poppins-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/Poppins/Poppins-700.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/Poppins/Poppins-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/Poppins/Poppins-700.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 400;
  src: url('../../fonts/NunitoSans/NunitoSans-400.eot'); /* IE9 Compat Modes */
  src:
    url('../../fonts/NunitoSans/NunitoSans-400.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/NunitoSans/NunitoSans-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/NunitoSans/NunitoSans-400.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/NunitoSans/NunitoSans-400.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/NunitoSans/NunitoSans-400.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 600;
  src: url('../../fonts/NunitoSans/NunitoSans-600.eot'); /* IE9 Compat Modes */
  src:
    url('../../fonts/NunitoSans/NunitoSans-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/NunitoSans/NunitoSans-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/NunitoSans/NunitoSans-600.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/NunitoSans/NunitoSans-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/NunitoSans/NunitoSans-600.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 700;
  src: url('../../fonts/NunitoSans/NunitoSans-700.eot'); /* IE9 Compat Modes */
  src:
    url('../../fonts/NunitoSans/NunitoSans-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../fonts/NunitoSans/NunitoSans-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../fonts/NunitoSans/NunitoSans-700.woff') format('woff'), /* Modern Browsers */
    url('../../fonts/NunitoSans/NunitoSans-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../fonts/NunitoSans/NunitoSans-700.svg') format('svg'); /* Legacy iOS */
}
