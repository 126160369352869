.page-header {
  @function header-images-url($file-path) {
    @return url(../../images/headers/#{$file-path});
  }

  @mixin background-gradient($color1, $color2, $color3, $image) {
    background-image: linear-gradient(to right, $color1 38%, $color2 99%, $color3 100%), header-images-url($image);
  }

  &.background {
    $navbar-height: 72px;
    $collapsed-navbar-height: 56px;

    background-color: $dark-blue;
    background-size: 40px 40px;
    background-image: linear-gradient(to right, $grid-line-blue 1px, transparent 1px),   
    linear-gradient(to bottom, $grid-line-blue 1px, transparent 1px);
    
    width: 100%;
    height: calc(100% + #{$navbar-height});
    padding-top: $navbar-height;
    margin-top: -$navbar-height;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 768px) {
      padding-top: $collapsed-navbar-height;
      margin-top: -$collapsed-navbar-height;
      height: calc(100% + #{$collapsed-navbar-height});
    }

    .content-container {
      display: flex; 
      flex-direction: row; 
      justify-content: center; 

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    &.home {
      &.text {
        width: '50%'
      }

      .home-image {
        width: 50%; 
        padding-left: 50px;
        object-fit: contain; 

        @media screen and (max-width: 1000px) {
            display: none; 
        }
      }  
    }

    /* &.projects {
      @include background-gradient($bp-blue-95, $bp-blue-75, $bp-blue-75, 'projects.jpg');
    } */

    /* &.nonprofits {
      @include background-gradient($bp-blue-95, $bp-blue-75, $bp-blue-75, 'nonprofits.jpg');
    } */

    &.students {
      .students-image {
        width: 70%; 
        padding-left: 30px;
        object-fit: contain; 
      }    

      @media screen and (max-width: 768px) {
        .students-image {
          padding: 15px 0 0 0; 
          width: 100%; 
        }
      }
    }

    &.about {
      // @include background-gradient($bp-blue-95, $bp-blue-75, $bp-blue-75, 'about.jpg');

      background-position: center 20%;

      .about-image {
        width: 65%; 
        padding-left: 50px;
        object-fit: contain; 
      }  

      @media screen and (max-width: 768px) {
        .about-image {
          padding: 15px 0 0 0; 
          width: 100%; 
        }
      }

      @media screen and (max-aspect-ratio: 4/3) {
        background-position: top;
      }
    }
  }

  .content {
    align-items: center;
    color: $white;
    display: flex;
    font-family: $syne;
    padding-bottom: 4rem;
    padding-top: 3rem;
    min-height: 55vh;

    .title {
      font-size: 3rem;
      font-weight: 700;

      @media (max-width: 39.99rem) {
        font-size: 10.3vw;
      }

      @media (min-width: 72rem) {
        font-size: 4rem;
      }
    }

    .subtitle {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 1rem;

      @media (max-width: 39.99rem) {
        font-size: 10.3vw;
      }

      @media (min-width: 72rem) {
        font-size: 4rem;
      }
    }

    .body {
      font-size: 1.2rem;
      line-height: 3rem;
      // opacity: 0.7;
      width: 50%;
    }
  }

  .btn {
    @extend .raised-button;
  }

  .sign-up {
    margin-top: 50px;
    font-size: 1.25rem;
    font-weight: 500;
  }

  &.inverse {
    &.background {
      background-color: $white;
      background-image: linear-gradient(to right, $light-blue 1px, transparent 1px), linear-gradient(to bottom, $light-blue 1px, transparent 1px);


      &.ptv {

        .body {
            display: flex; 
            justify-content: center; 
        }
        
        .ptv-text {
          margin-top: 50px;
          margin-bottom: 1rem;
          font-size: 1.25rem;
          font-weight: 500;
        }

        .btn {
          margin-right: 20px;
          margin-bottom: 20px;
        }
      }

      &.harmony-project {
        .body {
          display: flex; 
          justify-content: center; 
        }
      }
    }

    .content {
      color: $black;
    }
  }
}

.home {
  .content {
    .body {
      @media screen and (min-width: 992px) {
        width: 40vw;
      }
    }
  }
}
