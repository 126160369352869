.our-projects {
  background-color: $off-white;
  text-align: center;

  .pink-half-circle {
    $radius: 3em;
    $diameter: 2*$radius;

    margin: auto;
    width: $diameter;
    height: $radius;
    border-radius: $diameter $diameter 0 0;
    background-color: $pink;
  }

  .project-button {
    justify-content: center;
  }
  
  .card-deck {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .project-card {
      width: 33%;
      display: flex;
      flex: 1 0 26%;

      @media screen and (max-width: 768px) {
        width: 80%;
        flex: 1;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .card-body {
        height: 100px;
      }
    }
  }
}
