.values-template {
  padding-top: 60px;
  color: $black;
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1 0 26%;

  &:not(:first-child):not(:last-child) {
    margin-left: 30px;
    margin-right: 30px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;

    &:not(:first-child):not(:last-child) {
      margin-left: 0;
      margin-right: 0;
    }

    max-width: 300px;
  }

  .values-title {
    font-size: 28px;
    font-weight: 600;
    padding-top: 10px;
    font-family: $syne;
    color: $dark-gray;
  }

  .values-text {
    padding-top: 15px;
    color: $dark-gray;
    font-size: 0.9rem;
  }
}
