.btn {
  @extend .raised-button;
}

.mailing-list {
  display: flex;
  flex-direction: row;
  align-items: center;

  .mailing-list-input {
    font-family: $sans-serif;
    font-size: 1.25rem;
    border-radius: 3px;
    padding-left: 10px;
    margin-right: 20px;
    height: 46px;
    background-color: $white;
    border-style: none;
    outline: none;

    @media screen and (max-width: 576px) {
      font-size: 1rem;
      width: 200px;
      margin-right: 20px;
    }
  }
}
